import React, { useMemo } from "react";
import Fade from "react-reveal";
import { Button } from "semantic-ui-react";

const ByFactory = (props) => {
  const { partners } = props.data;

  // const fadeContents = useMemo(() => {
  //   return (
  //     <Fade duration={2000}>
  //       {contents.map((content, i) => {
  //         if (i % 2 === 0) {
  //           return (
  //             <div className="fac-content">
  //               <div className="content-desc">
  //                 <h2>{content.header}</h2>
  //                 {content.descs.map((desc) => (
  //                   <p>{desc}</p>
  //                 ))}
  //               </div>
  //               <div className="content-image">
  //                 <img src={content.image} alt="dao1" />
  //               </div>
  //             </div>
  //           );
  //         } else {
  //           return (
  //             <div className="fac-content">
  //               <div className="content-image">
  //                 <img src={content.image} alt="dao1" />
  //               </div>
  //               <div className="content-desc">
  //                 <h2>{content.header}</h2>
  //                 {content.descs.map((desc) => (
  //                   <p>{desc}</p>
  //                 ))}
  //               </div>
  //             </div>
  //           );
  //         }
  //       })}
  //     </Fade>
  //   );
  // }, [contents]);

  return (
    <section id="byfactory">
      <div className="row">
        <h1 className="solution">Our Partners</h1>
      </div>
      <div className="partner-logo-row">
        <div className="partner-logo-row-container">
          <div className="column">
            <img className="partner-img" src={partners.msit} alt="MSIT" />
          </div>
          <div className="column">
            <img className="partner-img" src={partners.busan} alt="Busan" />
          </div>
          <div className="column">
            <img className="partner-img" src={partners.kisa} alt="KISA" />
          </div>

          <div className="column">
            <img className="partner-img" src={partners.btp} alt="Busan TP" />
          </div>

          <div className="column">
            <img
              className="partner-img"
              src={partners.klaytn}
              alt="Klaytn Foundation"
            />
          </div>
          <div className="column">
            <img className="partner-img" src={partners.biyard} alt="Biyard" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ByFactory;
