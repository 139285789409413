import "./App.css";
import Header from "./components/Header";
import About from "./components/About";
import Footer from "./components/Footer";
import Partners from "./components/ByFactory";
import Subscribe from "./components/Subscribe";

const data = {
  partners: {
    msit: "/partners/msit_logo.png",
    busan: "/partners/busan_logo.png",
    kisa: "/partners/kisa_logo.png",
    btp: "/partners/btp-logo.png",
    klaytn: "/partners/klaytn-logo.png",
    biyard: "/partners/biyard_logo.png",
  },
  name: "매드앱(주)",
  description: "매드앱(주)",
  logo: "/madapp_logo.png",
  logo2: "/madapp_logo2.png",
  logo3: "MadApp_logo3.png",
  ByFactory_logo: "/ByFactory-logo2.png",
  prenet_logo: "/prenet_logo.png",
  image: "/madapp_logo.png",
  team: "/MadAppTeam.jpg",
  aboutDesc1: "준비중",
  contactmessage:
    "Here is where you should write your message to readers to have them get in contact with you.",
  email: "support@madapp.co.kr",
  tel: "070.4236.7160",
  github: "https://github.com/mad-app",
  address: {
    street: "부산광역시 동래구 충렬대로 108번길 6, 2층",
    zip: "47822",
  },

  website: "https://www.madapp.io",
  social: [
    {
      name: "github",
      url: "https://github.com/mad-app",
      className: "fa fa-github",
    },
  ],
  byFactory: {
    logo: "/ByFactory-logo.png",
    header: "Web3의 목표와 가치를 전달하는\nDAO-as-a-Service",
    contents: [
      {
        header: "쉬운 DAO 제작과 활용성",
        descs: [
          "블록체인 개발자 없이도 누구나 쉽게 템플릿 기반으로 만들 수 있는 DAO",
          "벤처캐피탈, 대기업, 스트타업, 협회, 재단 등 ‘의사결정의 투명성과 효율성’, ‘블록체인 기반의 참여적 조직 운영’을 원하는 모든 조직에서 활용 가능",
        ],
        image: "/dao1.png",
      },
      {
        header: "민주적인 투표와 자율 결정",
        descs: [
          "조직 내 구성원 간의 자율적인 의견 제시와 조율, 투표로 이루어지는 민주적인 조직 운영 방식",
          "클레이튼 블록체인에 배포된 스마트컨트랙트로 이루어지는 믿을 수 있는 의사결정",
        ],
        image: "/dao2.png",
      },
      {
        header: "투명한 조직 관리",
        descs: [
          "DAO 내에서 이루어지는 모든 의사결정들이 투명하게 공개",
          "블록체인의 특성으로 인해 이러한 모든 내용들은 위변조 불가능",
        ],
        image: "/dao3.png",
      },
    ],
  },
};
function App() {
  return (
    <div className="App">
      <Header data={data} />
      <About data={data} />
      <Subscribe data={data} />
      <Partners data={data} />
      <Footer data={data} />
    </div>
  );
}

export default App;
