import React, { useMemo } from "react";
import Fade from "react-reveal";
import { Button } from "semantic-ui-react";

const Subscribe = (props) => {
  // const { partners } = props.data;

  return (
    <section id="subscribe">
      <div className="container_fl">
        <div className="row-flex subscribe-row">
          <div className="col-lg-6 f-ill">
            <div className="footer-illustration card_ol1">
              <h1 className="white">
                Subscribe{" "}
                <span className="c-dblue">
                  {" "}
                  to our <br /> Beta waitlist 👀
                </span>
              </h1>
              <br />
              <Button
                className="subscribe round"
                onClick={() => {
                  window.open(
                    "mailto:support@madapp.co.kr?subject=[Subscribe] Questions on the beta testing",
                    "_blank"
                  );
                }}
              >
                Get notified
                <span className="arrow">
                  <i className="bi bi-arrow-right"></i>
                </span>
              </Button>
            </div>
          </div>
          <div className="col-lg-6 f-ill">
            <div className="footer-illustration card_ol1">
              <h1 className="white">
                Curious? <br /> <span className="c-dblue">Let's talk</span>
              </h1>
              <br />
              <Button
                className="round"
                onClick={() => {
                  window.open(
                    "mailto:support@madapp.co.kr?subject=[Book a time] Asking for a meeting",
                    "_blank"
                  );
                }}
              >
                Book a time
                <span className="arrow">
                  <i className="bi bi-arrow-right"></i>
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
