import React from "react";
import { Fade } from "react-reveal";
import { Button } from "semantic-ui-react";

const Header = (props) => {
  const logo = props.data.logo3;
  const madapp_logo = props.data.logo;

  const hrefButton = () => {
    window.location.href = "https://byfactory.io/";
  };
  return (
    <header id="header">
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
          <div className="imageBox">
            <img src={logo} alt="logo" />
          </div>
          <div>
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                About
              </a>
            </li>
            <li>
              <a className="smoothscroll" href="#byfactory">
                ByFactory
              </a>
            </li>
          </div>
        </ul>
      </nav>

      <section id="home">
        <Fade duration={2000}>
          <div className="row">
            <div className="main-col">
              <div className="row">
                <div className="columns">
                  <div className="SignatureBackground">
                    <div>
                      <h1>Madapp</h1>
                      <h2>
                        Revolutionizing trust on web3 with a secure technology
                      </h2>
                      {/* <Button onClick={hrefButton}>시작하기</Button> */}
                    </div>
                    <img src={madapp_logo} alt="logo2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </header>
  );
};

export default Header;
