import React from "react";
import Fade from "react-reveal";
import { Button } from "semantic-ui-react";

const About = (props) => {
  const byfactory_logo = props.data.ByFactory_logo;
  const prenet_logo = props.data.prenet_logo;

  return (
    <section id="about">
      <Fade duration={1000}>
        <div className="row">
          <h1 className="solution">Our Industry Solutions</h1>
        </div>
        <div className="row">
          <div className="column">
            <div className="row">
              <div className="imageBox">
                <img src={byfactory_logo} alt="ByFactory" />
              </div>
            </div>
            <div className="rowTop row centering">
              <Button
                target="_blank"
                onClick={() => {
                  window.open("https://byfactory.io", "_blank");
                }}
              >
                Learn more...
              </Button>
            </div>
          </div>
          <div className="column">
            <div className="row">
              <div className="imageBox">
                <img src={prenet_logo} alt="PRENET" />
              </div>
            </div>
            <div className="row centering">
              <Button
                target="_blank"
                onClick={() => {
                  window.open(
                    "https://explorer.prenet.blockchainbusan.kr/",
                    "_blank"
                  );
                }}
              >
                Learn more...
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};

export default About;
